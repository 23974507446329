import React from "react";

import "./updateHarvestDataSheetModal.css";

const CustomPopup = ({
	close,
	title,
	description,
	noText = "No",
	yesText = "Yes",
	yesFunc = () => {},
}) => {
	return (
		<div className='modalReminder'>
			<div className='ModalBodyReminder customHarvestPopup'>
				<h1>{title}</h1>
				{description && (
					<span className='havrestSubheading'>{description}</span>
				)}

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<button
						className='harvest-action-button'
						style={{
							borderColor: "#333",
							backgroundColor: "#333",
							color: "white",
						}}
						onClick={close}
					>
						{noText}
					</button>
					<button
						className='harvest-action-button'
						style={{
							borderColor: "#4256D0",
							backgroundColor: "#4256D0",
							color: "white",
						}}
						onClick={yesFunc}
					>
						{yesText}
					</button>
				</div>
			</div>
		</div>
	);
};

export default CustomPopup;
