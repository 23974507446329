import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import "./sanfrancisco-font.css";
import "./inter-font.css";
import "./gilroy-font.css";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import NotificationHandler from "./notificationHandler";
//localStorage.clear();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}
ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */} {/* Provide Redux store */}
    <Router>
      <NotificationHandler />
      <App /> {/* Use App component directly */}
    </Router>
    {/* </Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
