import React from "react";

import "./updateHarvestDataSheetModal.css";

const UpdateHarvestDataSheetModal = ({
	show,
	close,
	onClickUpdate = () => {},
}) => {
	return (
		<div className='modalReminder'>
			<div className='ModalBodyReminder harvestModalBody'>
				<h1>Harvest DataSheet</h1>
				<span className='havrestSubheading'>
					Clicking on "Open Harvest Sheet" will take you to the harvest sheet.
					You can also update the harvest sheet with the latest data by clicking
					on "Update Sheet". Harvest datasheet can take upto 3-5 minutes to
					update.
				</span>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<button
						onClick={close}
						className='harvest-action-button'
						style={{
							borderColor: "#333",
							backgroundColor: "#333",
							color: "white",
						}}
					>
						Close
					</button>

					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<button className='harvest-action-button' onClick={onClickUpdate}>
							Update Sheet
						</button>
						<button
							className='harvest-action-button'
							style={{
								borderColor: "#4256D0",
								backgroundColor: "#4256D0",
								color: "white",
							}}
							onClick={() =>
								window.open(
									"https://docs.google.com/spreadsheets/d/1_plF4utVcWYZVNSyDfu1xtcV4wzXEPGt9ymOlraRYJM/edit?gid=506740636#gid=506740636"
								)
							}
						>
							Open Harvest Sheet
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateHarvestDataSheetModal;
